/****************************
 * BASE - TYPE
 ****************************/

/**
 * Pseudo
 */

::selection {
  background: var(--gray);
  color: var(--white);
  text-shadow: none;
}

/**
 * General
 */

html {
  font-size: var(--html-font-size);
}

body {
  background: var(--white);
  color: var(--black);
  font-family: var(--font-primary);
  font-size: var(--body-font-size);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: var(--color-primary);

  &:hover,
  &:focus, {
    color: var(--color-secondary);
  }
   &:active {
    color: #fff;
  }
  &:focus {
    outline: none;
  }
}


/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: var(--line-short);

  a {
    color: var(--black);
  }
}


/**
 * Text Components
 */

p,
dl,
address {
  font-size: 1em;
  line-height: 1;
}

p,
li,
dt,
dd {
  font-weight: normal;
  font-style: normal;
}

li {
  line-height: 1;
}
