.page {
  min-height: 1000px;
}

/*general styles*/
.image-section {
  position: relative;
  img {
    width: 100%;
    display: block;
  }
}

.img-limit {
  width: 100%;
  max-width: 1200px;
}

p {
  position: absolute;
  width: 100%;
}

.section-text {
  position: absolute;
  z-index: 100;
}

/*section1*/
.hero {
  position: relative;
  margin-top: 94px;
}
.hero-sp {
  padding-top: 61px;
}

.hero-text h1 {
  position: absolute;
  width: 100%;
  top: 5vw;
  color: white;
  z-index: 100;
  font-size: 2.6vw;
  text-align: center;
  font-weight: 600;
  line-height: 1.5;
}

.hero-text h2 {
  position: absolute;
  width: 100%;
  z-index: 100;
  font-size: 1.2vw;
  text-align: center;
  font-weight: 600;
  line-height: 2.1;
  letter-spacing: -1.8px;
  color: #fff;
  top: 15.8vw;
}

.hero-heading-btn_pc {
  position: absolute;
  width: 40%;
  top: 79.1%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 100;
}

.hero-heading-btn_sp {
  position: absolute;
  z-index: 100;
  width: 86.9%;
  height: 10.3%;
  top: 72.5%;
  left: 6.4%;
}

.hero-sp {
  text-shadow: 1px 0px 10px rgba(0, 0, 0, 1);
  font-weight: bold;
  text-align: center;
  color: #fff
}
.hero-title-sp {
  position: absolute;
  z-index: 100;
  font-size: 5.4vw;
  width: 90%;
  top: 18.7%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-weight: 700;
  text-align: center;
  line-height: 1.5;
  text-shadow: 1px 0 10px #000;
}
.hero-text-sp {
  position: absolute;
  z-index: 100;
  font-size: 3.4vw;
  width: 77%;
  top: 53.6%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-weight: 700;
  text-align: center;
  line-height: 1.8;
  text-shadow: 1px 0 10px #000;
}

.hero-heading-btn_pc img {
  -webkit-transform: translateZ(0);
  z-index: 1;
  transition: opacity .4s;
}

.hero-heading-btn_pc:hover img {
  opacity: 0.8;
}

.hero-heading-btn_pc span {
  display: block;
  height: 99.4%;
  width: 99.9%;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
}

.cta-btn-pc {
  position: absolute;
  z-index: 100;
  color: #fff;
  background-color: #f77f17;
  text-decoration: none;
  text-align: center;
  margin: 0 auto;
  width: 35%;
  padding: 1.4% 0;
  font-size: 1.6vw;
  text-shadow: none;
  transition: background-color .6s;
  font-weight: 600;
  border-radius: 2px;
}

.cta-btn-pc:hover {
  background-color: #f99e4f;
  cursor: pointer;
  color: #fff;
}

.cta-btn-sp {
  position: absolute;
  z-index: 100;
  color: #fff;
  background-color: #f77f17;
  font-weight: 600;
  border-radius: 3px;
  text-decoration: none;
  text-align: center;
  top: 84.5%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 86.6%;
  height: 9.1%;
  padding: 5.3% 0;
  font-size: 4vw;
  text-shadow: none;
}

/*end of section 1*/

/*section 2*/
.section-2-btn_pc {
  position: absolute;
  width: 40%;
  top: 72.7%;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: opacity .4s;
}

.section-2-btn_pc:hover {
  opacity: .8;
}

.section-2-btn_sp {
  position: absolute;
  z-index: 100;
  top: 79.9%;
  left: 6.7%;
  width: 86.9%;
  height: 8.1%;
}

.section-2 .section-text-pc h4 {
  position: absolute;
  margin-top: 10.3vw;
  z-index: 100;
  width: 100%;
  text-align: center;
  line-height: 2.2;
  font-size: 1.6vw;
  letter-spacing: -0.5px;
}

.section-2 .section-text-pc span {
  margin-top: 23vw;
  z-index: 100;
  width: 100%;
  text-align: center;
  line-height: 2;
  font-size: 1.4vw;
  letter-spacing: -.5px;
  color: #333;
}

.section-2 .section-text-pc p {
  position: absolute;
  top: 18.4vw;
  z-index: 100;
  width: 100%;
  text-align: center;
  line-height: 2;
  font-size: 1.4vw;
  letter-spacing: -0.5px;
  color: #333;
}

.section-2 .cta-btn-pc {
  top: 45vw;
  left: 33.2vw;
}

.section-2 .desktop-only {
  height: 60.5vw;
}

.section-2 .section-text-sp h4 {
  position: absolute;
  z-index: 100;
  width: 88%;
  text-align: left;
  line-height: 1.8;
  font-size: 3.6vw;
  letter-spacing: 0.7px;
  top: 13vw;
  left: 6vw;
}

.section-2 .section-text-sp span {
  position: absolute;
  z-index: 100;
  margin-top: 8.5vw;
  border-bottom: 2px #333 solid;
  text-align: center;
  font-size: 1.4vw;
  height: 2vw;
  width: 11vw;
  left: 38.8vw;
}

.section-2 .section-text-sp p {
  position: absolute;
  top: 36vw;
  z-index: 100;
  width: 87%;
  text-align: left;
  line-height: 1.9;
  font-size: 3.4vw;
  letter-spacing: -.5px;
  color: #333;
  left: 6vw;
}

.section-2 .cta-btn-sp {
  height: 8%;
  top: 81.2%;
  padding: 4.3% 0;
}


/*end of section2*/

/*section 3 image*/

/*end of section 3 image*/

/*section 4*/
/* .section-4-btn_pc {
  position: absolute;
  width: 40%;
  top: 82.2%;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: opacity .4s;
}

.section-4-btn_pc:hover {
  opacity: .8;
} */

.section-4-subtext {
  text-align: left;
  color: #262f44;
  font-family: 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;
  font-size: 1.7vw;
  font-weight: 600;
  position: relative;
  letter-spacing: 0.02vw;
  left: 60.5%;
}
.section-4-subtext-1 {
  top: 23.7vw;
}
.section-4-subtext-2 {
  top: 31.7vw;
}
.section-4-subtext-3 {
  top: 41.4vw;
}
.section-4-subtext-4 {
  top: 51.2vw;
}
.section-4-subtext-5 {
  top: 58.8vw;
  line-height: 2.8vw;
}
.main .section .section-4-header_pc h3 {
  top: 10.6vw;
  left: 37.8vw;
  letter-spacing: .1vw;
  font-weight: 600;
  font-size: 4.1vw;
}
.main .section .section-4-header_pc h4 {
  top: 11.9vw;
  left: 40vw;
  letter-spacing: .1vw;
  font-weight: 600;
  font-size: 1.6vw;
}
.section-4-text_pc p {
  text-align: left;
  width: 49.6%;
  left: 39.6%;
  font-size: 1.2vw;
  letter-spacing: -.02vw;
  line-height: 2.01vw;
}
.section-4-text_pc{
  left: 50.6%;
  width: 39%;
  line-height: 2.1vw;
  font-size: 1.2vw;
}
.section-4-text-1_pc {
  top: 21vw;
}
.section-4-text-2_pc {
  top: 32.1vw;
}
.section-4-text-2_pc span {
  position: absolute;
  width: 98%;
  font-size: 1vw;
  margin-top: 2%;
  line-height: 1.7vw;
}
.section-4-text-3_pc {
  top: 43.5vw;
}
.section-4-text-4_pc {
  top: 53.9vw;
}
.section-4-text-5_pc {
  top: 63.8vw;
}

.section-4 .cta-btn {
  top: 91%;
  width: 50%;
  font-weight: 600;
  padding: 1.6% 0;
  height: 5.5%;
}

.section-4-btn_sp {
  position: absolute;
  z-index: 100;
  top: 91.1%;
  left: 6.7%;
  width: 86.9%;
  height: 4.4%;
}

.service-bookmark {
  position: relative;
  top: -90px;
}

.section-4 .section-text {
  color: #262f44;
}

.section-4 .cta-btn-pc {
  left: 34%;
  top: 88%;
}

.section-4 .cta-btn-sp {
  height: 4.34%;
  top: 91.1%;
  padding: 4.3% 0;
}

.section-4-header_sp {
  top: 8.9%;
  left: 50%;
  text-align: center;
  margin-right: 0;
  transform: translate(-50%, -50%);
  width: 100%;
}

.section-4-header_sp h3 {
  font-size: 8.1vw;
  margin-bottom: 3.4vw;
}

.section-4-subtext-sp {
  text-align: left;
  color: #262f44;
  font-family: Hiragino Kaku Gothic Pro,'ヒラギノ角ゴ Pro W3',Meiryo,"メイリオ",Osaka,MS PGothic,arial,helvetica,sans-serif;
  font-size: 4.7vw;
  font-weight: 600;
  position: relative;
  letter-spacing: .02vw;
  left: 19%;
}

.section-4-subtext-1-sp {
  top: 46.9vw;
}
.section-4-subtext-2-sp {
  top: 89.9vw;
}
.section-4-subtext-3-sp {
  top: 126.2vw;
}
.section-4-subtext-4-sp {
  top: 167.7vw;
}
.section-4-subtext-5-sp {
  top: 204.1vw;
}

.section-4-text_sp {
  left: 6.6%;
  top: 17%;
  width: 90%;
}
.section-4-text_sp p {
  text-align: left;
  font-size: 3.6vw;
  letter-spacing: -0.22vw;
  line-height: 6.31vw;
}

.section-4-text-1_sp {
  top: 6.7vw;
}
.section-4-text-2_sp {
  top: 53.7vw;
}
.section-4-text-2_sp span {
  position: absolute;
  width: 96%;
  font-size: 2.9vw;
  margin-top: 2%;
  line-height: 6.1vw;
}
.section-4-text-3_sp {
  top: 94.4vw;
}
.section-4-text-4_sp {
  top: 141.1vw;
}
.section-4-text-5_sp {
  top: 181.9vw;
}

/*end of section 4 Our services*/

/*section 5 Accordion*/

/* .case-study-link {
  position: absolute;
  top: -0.05%;
  width: 38%;
  height: 3.3%;
  z-index: 1;
  display: block;
  background-color: var(--white);
  opacity: 0;
  z-index: 1000;
  transition: opacity .4s;
  &:hover {
    opacity: 0.2;
  }
  &.active {
    &:hover {
      opacity: 0.3;
    }
  }
  &-1 {
    height: 8.3%;
    left: 11.2%;
    &.active {
      height: 8.3%;
    }
  }
  &-2 {
    height: 8.2%;
    left: 50.8%;
    &.active {
      height: 8.2%;
    }
  }
} */

.case-study-wrapper {
  display: none;
  position: relative;
  &.active {
    display: block;
  }
}

.case-study-bookmark {
  position: relative;
  top: -90px;
}

.main .section .section-5-header_pc h3 {
  top: 6.2vw;
  left: 38.1vw;
  letter-spacing: .1vw;
  font-weight: 600;
  font-size: 4.1vw;
}
.main .section .section-5-header_pc h4 {
  top: 7.4vw;
  left: 45.8vw;
  letter-spacing: .1vw;
  font-weight: 600;
  font-size: 1.6vw;
}
.main .section .section-5-header_pc p {
  top: 16.4vw;
  left: 32.6vw;
  letter-spacing: -0.1vw;
  font-size: 1.4vw;
  width: 38vw;
  color: #333;
}
.section-5_1-btn_pc, .section-5_2-btn_pc {
  position: absolute;
  top: 78.3%;
  width: 10.4%;
  height: 5%;
  opacity: 0;
  background-color: #fff;
  transition: opacity .4s;
}
.section-5_1-btn_pc {
  left: 25.1%;
}
.section-5_2-btn_pc {
  left: 64.5%;
}
.section-5_1-btn_pc:hover, .section-5_2-btn_pc:hover {
  opacity: .4;
}
.section-5 .cta-btn-pc {
  bottom: 6vw;
  left: 33.5vw;
}

.section-5-card-text-pc {
  position: absolute;
  width: 34%;
  z-index: 100;
  color: #333;
  letter-spacing: 2px;
  line-height: 1.8vw;
  text-align: left;
  left: 13.6%;
  top: 60.3%;
}

.section-5-card-title {
  text-align: center;
  font-size: 1.8vw;
  font-weight: 600;
  color: #262f44;
  letter-spacing: -0.09vw;
  margin-bottom: 2.1vw;
}

.section-5-card-description {
  margin-bottom: 2.7vw;
  color: #4d4d4d;
  font-size: 1.2vw;
  letter-spacing: -0.1vw;
  line-height: 2vw;
}

.main .section .section-5-card-link {
  text-align: center;
  left: -0.5vw;
}

.main .section .section-5-card-link-2 {
  margin-top: 4.6vw;
}

.main .section .section-5-card-link a {
  font-weight: 600;
  color: #f77f17;
  font-size: 1.01em;
  transition: color .4s;
  letter-spacing: -0.05vw;
}

.main .section .section-5-card-link a:hover {
  color: #dd8e4a;
}

.section-5-card-text-2 {
  left: 52.6%;
}

.section-5-header_sp {
  top: 7%;
  left: 50%;
  text-align: center;
  margin-right: 0;
  transform: translate(-50%, -50%);
  width: 100%;
}

.section-5-header_sp h3 {
  font-size: 8.1vw;
  margin-bottom: 3.4vw;
}

.section-5-header_sp h4 {
  margin-bottom: 4vw;
}

.section-5-header_sp p {
  font-size: 3.6vw;
  color: #333;
  line-height: 6vw;
}

.main .section .section-5-card-link-sp {
  text-align: center;
  left: -0.5vw;
}

.main .section .section-5-card-link-sp a {
  font-weight: 600;
  color: #f77f17;
  font-size: 1.01em;
  transition: color .4s;
  letter-spacing: -0.05vw;
}

.section-5-card-text-sp {
  position: absolute;
  width: 85%;
  z-index: 100;
  color: black;
  line-height: 6.2vw;
  text-align: left;
  left: 8.7%;
  top: 36.5%;
  font-size: 3.5vw;
  letter-spacing: -0.1vw;
}

.section-5-card-title-sp {
  font-size: 5vw;
  text-align: center;
  font-weight: 700;
  color: #262f44;
  letter-spacing: -0.09vw;
  margin-bottom: 2vw;
}

.section-5-card-border-sp {
  margin: 6% 0;
}

.section-5-card-description-sp {
  margin-bottom: 4vw;
  color: #4d4d4d;
}

.section-5-card-text-2-sp {
  top: 78.4%;
  left: 7%;
}

/*end of section 5 Accordion*/

/*section 6 Case Study*/

.clients-bookmark {
  position: relative;
  top: -40px;
}

.section-6 {
  margin-top: -5.5vw;
}

@media (--small) {

  .section-6 {
    margin-top: -15.5vw;
  }

}

.main .section .section-6-header_pc h3 {
  top: 6.2vw;
  left: 38.1vw;
  letter-spacing: .1vw;
  font-weight: 600;
  font-size: 4.1vw;
}
.main .section .section-6-header_pc h4 {
  top: 7.4vw;
  left: 45.8vw;
  letter-spacing: .1vw;
  font-weight: 600;
  font-size: 1.6vw;
}
.main .section .section-6-header_pc p {
  top: 16.4vw;
  left: 32.6vw;
  letter-spacing: -0.1vw;
  font-size: 1.4vw;
  width: 38vw;
  color: #333;
}
.section-6_1-btn_pc, .section-6_2-btn_pc {
  position: absolute;
  top: 78.3%;
  width: 10.4%;
  height: 5%;
  opacity: 0;
  background-color: #fff;
  transition: opacity .4s;
}
.section-6_1-btn_pc {
  left: 25.1%;
}
.section-6_2-btn_pc {
  left: 64.5%;
}
.section-6_1-btn_pc:hover, .section-6_2-btn_pc:hover {
  opacity: .4;
}
.section-6 .cta-btn-pc {
  bottom: 8vw;
  left: 33.5vw;
}

.section-6-card-text-pc {
  position: absolute;
  width: 34%;
  z-index: 100;
  color: #333;
  letter-spacing: 2px;
  line-height: 1.8vw;
  text-align: left;
  left: 13.6%;
  top: 60.3%;
}

.section-6-card-title {
  text-align: center;
  font-size: 1.8vw;
  font-weight: 600;
  color: #262f44;
  letter-spacing: -0.09vw;
  margin-bottom: 2.1vw;
}

.section-6-card-description {
  margin-bottom: 2.7vw;
  color: #4d4d4d;
  font-size: 1.2vw;
  letter-spacing: -0.1vw;
  line-height: 2vw;
}

.main .section .section-6-card-link {
  text-align: center;
  left: -0.5vw;
}

.main .section .section-6-card-link-2 {
  margin-top: 4.6vw;
}

.main .section .section-6-card-link a {
  font-weight: 600;
  color: #f77f17;
  font-size: 1.01em;
  transition: color .4s;
  letter-spacing: -0.05vw;
}

.main .section .section-6-card-link a:hover {
  color: #dd8e4a;
}

.section-6-card-text-2 {
  left: 52.6%;
}

.section-6 .cta-btn-sp {
  padding: 4.3% 0;
}

.section-6-header_sp {
  top: 14%;
  left: 50%;
  text-align: center;
  margin-right: 0;
  transform: translate(-50%, -50%);
  width: 100%;
}

.section-6-header_sp h3 {
  font-size: 8.1vw;
  margin-bottom: 3.4vw;
}

.section-6-header_sp h4 {
  margin-bottom: 4vw;
}

.section-6-header_sp p {
  font-size: 3.6vw;
  color: #333;
  line-height: 6vw;
}

.main .section .section-6-card-link-sp {
  text-align: center;
  left: -0.5vw;
}

.main .section .section-6-card-link-sp a {
  font-weight: 600;
  color: #f77f17;
  font-size: 1.01em;
  transition: color .4s;
  letter-spacing: -0.05vw;
}

.section-6-card-text-sp {
  position: absolute;
  width: 85%;
  z-index: 100;
  color: black;
  line-height: 6.2vw;
  text-align: left;
  left: 8.7%;
  top: 36.5%;
  font-size: 3.5vw;
  letter-spacing: -0.1vw;
}

.section-6-card-title-sp {
  font-size: 5vw;
  text-align: center;
  font-weight: 700;
  color: #262f44;
  letter-spacing: -0.09vw;
  margin-bottom: 2vw;
}

.section-6-card-border-sp {
  margin: 6% 0;
}

.section-6-card-description-sp {
  margin-bottom: 4vw;
  color: #4d4d4d;
}

.section-6-card-text-2-sp {
  top: 78.4%;
  left: 7%;
}

/*end of section 6 Case Study*/

/*section 7*/
.service-flow-text {
  position: absolute;
  z-index: 100;
  top: 13.4%;
  left: 38%;
  letter-spacing: 2px;
  &-heading {
    font-family: Times, serif;
    font-size: 4.2vw;
    color: var(--white);
    font-weight: 400;
    margin-bottom: 31.8px;
  }
  &-label {
    font-size: 1.1vw;
    font-weight: 600;
    color: #cba06e;
    left: 0.6vw;
    bottom: .8vw;
    margin-bottom: 55px;
    letter-spacing: 0.5px;
  }
  &-contact {
    color: var(--white);
    line-height: 31px;
    top: -0.5vw;
    left: -4.1vw;
    letter-spacing: 1.5px;
    font-size: 1.3vw;
  }
}

.service-flow-link {
  color: var(--white);
  &:hover {
    color: var(--white);
    opacity: 0.4;
  }
}

.contact-link {
  position: absolute;
  top: 23.6%;
  left: 32.5%;
  width: 18.6%;
  height: 1.4%;
  z-index: 1;
  display: block;
  background-color: #00001b;
  opacity: 0;
  &:hover {
    opacity: .2;
  }
}

.service-flow-order {
  position: absolute;
  top: 31.2%;
  left: 28.5%;
  z-index: 100;
  color: var(--white);
  font-family: var(--font-primary);
  &-text {
    font-size: 1.9vw;
    letter-spacing: .8px;
    margin-bottom: 29%;
    top: 7.6vw;
    left: 9.3vw;
    color: #cba06e;
  }
  &-disclaimer {
    left: 40.5vw;
    top: 9vw;
    letter-spacing: 1.4px;
  }
}

.section-7 .bookmark {
  position: relative;
  top: 7vw;
}

.service-flow-text-move {
  top: 6.5vw;
}

/*end of section 7*/


/*section 8 About Us*/

.section-8-header_pc {
  top: 21%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.main .section .section-8-header_pc h3 {
  letter-spacing: .1vw;
  font-weight: 600;
  font-size: 4.1vw;
}
.section-8-header_pc h4 {
  margin-top: 12px;
  font-weight: 600;
  font-size: 1.6vw;
}
.section-8-text_pc {
  text-align: center;
  top: 28.3%;
  font-size: 1.3vw;
  line-height: 2.9vw;
  letter-spacing: -.04vw;
}
.section-8-text_pc span {
  letter-spacing: 0vw;
}
.main .section .section-8-header_sp h3 {
  font-size: 8.1vw;
}
.main .section .section-8-header_sp h4 {
  font-size: 4.1vw;
  margin-top: 10px;
}
.section-8-header_sp {
  text-align: center;
  width: 90%;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.section-8-text_sp {
  text-align: center;
  width: 75.5%;
  top: 15.1%;
  left: 12.7%;
  font-size: 3.5vw;
  line-height: 6vw;
  color: #333;
}

.addresses {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 58%;
  width: 72.6%;
}

.address {
  width: 100%;
  display: block;
  z-index: 2000;
  margin-left: 3.6%;
}
.address:first-child {
  margin-left: 0;
}

.flip-container {
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -o-perspective: 1000;
  perspective: 1000;
}

.flip-container:hover .flipper {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.flip-container, .flipper-front, .flipper-back {
  width: 100%;
}

.flipper {
  -webkit-transition: 1s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 1s;
  -moz-transform-style: preserve-3d;
  -o-transition: 1s;
  -o-transform-style: preserve-3d;
  transition: 1s;
  transform-style: preserve-3d;
  position: relative;
}

.flipper-front, .flipper-back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.flipper-front {
  z-index: 2;
}

.flipper-back {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.flipper-img {
  width: 100%;
}

.section-8 .cta-btn-pc {
  position: absolute;
  top: 84.6%;
  left: 0;
  right: 0;
}

.section-8 .cta-btn-pc:hover {
  color: #fff;
}

.section-8 .cta-btn-sp {
  top: 92%;
  height: 5.5%;
}

/*end of section 8*/

/*section 6 About Us*/
.about-bookmark {
  position: relative;
  top: -90px;
}

.about-us-content {
  position: absolute;
  top: 30.3%;
  left: 12.6%;
  &-p {
    margin-bottom: 1.9vw;
    font-size: 1.2vw;
    font-family: 'Hiragino Sans', 'Hiragino Kaku Gothic Pro', sans-serif;
    line-height: 2.2;
    color: #00001b;
    font-weight: 300;
  }
}

.about {
  z-index: 0;
  position: relative;
}

.about-btn {
  position: absolute;
  right: 12.6%;
  bottom: 37.3%;
  a {
    &:hover {
      img {
        opacity: 0.8;
      }
    }
  }
}

.section-9__btn {
  position: absolute;
  top: 67%;
  left: 31.2%;
  width: 38%;
  transition: opacity .4s;
}

.section-9__btn:hover {
  opacity: .8;
}

.section-9__btn-sp {
  position: absolute;
  z-index: 100;
  width: 86.5%;
  height: 16.8%;
  bottom: 14.6%;
  left: 6.7%;
}

/*end of section 9 About Us*/

/*section 10 Contact*/

.section-10 {
  margin-bottom: -1px;
  position: relative;
  z-index: 1;
}

.contact-bookmark {
  position: relative;
  top: -90px;
}

.contact {
  &-section {
    background-color: #00001b;
    padding: 128px 0 128px 0;
    font-size: 12px;
  }
  &-inner {
    @mixin clearfix;
    width: 78.3%;
    max-width: 940px;
    margin: 0 auto;
  }
  &-form, &-text {
    float: left;
  }
  &-form {
    width: 64%;
    margin-top: 7px;
  }
  &-text {
    width: 36%;
    padding-left: 7px;
    padding-right: 15px;
    &-heading {
      font-family: var(--font-heading);
      font-size: 42px;
      color: var(--white);
      font-weight: 600;
      margin-bottom: 17px;
      letter-spacing: .11em;
    }
    &-label {
      font-size: 18px;
      color: var(--white);
      font-weight: 700;
      margin-bottom: 25px;
    }
    &-p {
      font-size: 16px;
      line-height: 2.2;
      color: var(--white);
      margin-top: 30px;
    }
  }
  &-table {
    display: table;
    width: 100%;
    margin: 0 auto;
  }
  &-row {
    display: table-row;
  }
  &-cell {
    position: relative;
    display: table-cell;
  }
  &-input, &-textarea {
    padding: 12px 12px 12px 21px !important;
    color: var(--color-primary);
  }
  &-input {
    &-required {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
      background-color: #fccca6;
      padding: 3px 5px;
      color: #fff;
      font-weight: 700;
    }
  }
  &-textarea {
    height: 150px;
  }
  &-btn {
    color: #fff;
    text-align: center;
    padding: 6px 15px !important;
    width: 100%;
    background-color: #f77f17;
    padding: 25px 12px 16px 12px !important;
    transition: background-color .6s;
    text-indent: -99999px;
    border: none;
    border-radius: 3px;
    box-sizing: border-box !important;
    cursor: pointer;
    &-text {
      position: absolute;
      top: 49%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      margin: 0 auto;
      color: #fff;
      transition: color .6s;
      pointer-events: none;
      font-size: 20px;
      font-weight: 700;
      letter-spacing: .06em;
      text-indent: 19px;
    }
    &-icon {
      position: relative;
      top: 2px;
      left: -16px;
      display: inline-block;
      width: 22px;
      height: 18px;
      background: transparent url('../img/icon__mail.png') no-repeat 0 0;
      background-size: 22px 18px;
      transition: background-image .6s;
    }
  }
  &-submit {
    position: relative;
    width: 100%;
    margin: 20px auto 0 auto !important;
    padding-left: 0 !important;
    text-align: center;
    cursor: pointer;
    &:hover {
      .contact-btn {
        background-color: #f7994e;
      }
    }
  }
  &-confirmation {
    height: 280px;
    display: none;
    &-heading {
      font-family: var(--font-heading);
      font-size: 42px;
      color: var(--white);
      font-weight: 600;
      letter-spacing: .11em;
    }
    &-p {
      position: relative;
      top: 38%;
      transform: translateY(-50%);
      text-align: center;
      font-size: 26px;
      color: var(--white);
      line-height: 1.8;
    }
  }
}

.btn-fb,
.btn-tw,
.btn-li {
  position: absolute;
  z-index: 100;
  width: 3.1%;
  height: 43.2%;
  bottom: 15.6%;
  background-color: #262f44;
  border-radius: 20px;
  opacity: 0;
  transition: opacity .4s;
}

.btn-fb:hover,
.btn-tw:hover,
.btn-li:hover {
  opacity: .3;
}

.btn-fb {
  left: 39%;
}

.btn-tw {
  left: 48.3%;
}

.btn-li {
  left: 57.6%;
}

/*Hubspot form*/

.contact-form {
  form {
    margin-bottom: 0;
  }
  .hs-form {
    fieldset, .hs-input, .input {
      max-width: 100% !important;
      width: 100% !important;
      font-family: var(--font-primary);
    }
    .hs-input {
      box-sizing: border-box;
      font-size: 12px;
    }
    textarea.hs-input {
      height: 202px;
      padding: 12px 12px 12px 21px!important;
    }
    .hs-form-field {
      margin-bottom: 0 !important;
      label {
        margin-bottom: 0;
      }
    }
    .hs-error-msgs {
      margin-top: 4px;
      margin-bottom: 7px!important;
      label {
        color: #fff;
        font-size:12px;
      }
    }
    .hs-input {
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #666;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: #666;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: #666;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: #666;
      }
    }
  }
  input.hs-input, textarea.hs-input, select.hs-input {
    border-radius: 0;
    padding: 21px 12px 21px 21px;
    border: 1px solid #333;
    outline: none;
    color: #333;
    width: 100%;
    font-size: 12px;
  }

  .hs_submit {
    margin-top: 20px;
    .actions {
      padding: 0;
      margin: 0;
    }
  }
  .awesome-submit-button {
    color: #fff;
    text-align: center;
    padding: 4px 15px;
    width: 100%;
    border: 1px solid #fff;
    background-color: #00001b;
    padding: 14px 12px 16px 12px !important;
    transition: background-color .6s, color .6s;
    border-radius: 0;
    box-sizing: border-box !important;
    text-indent: -9999px;
    &:hover {
        background-color: var(--white);
        color: #00001b;
        opacity: 0.2;
    }
  }

  .input .hs-input {
    width: 100% !important;
  }

  .hs-form-required {
    display: none;
  }

  textarea.hs-input {
    height: 14vw;

  }

  .field {
    margin-bottom: 0;
  }
}

/*end of section 10*/


@media (--small) {

  /*Service Flow*/

  .contact-link {
    position: absolute;
    top: 30.6%;
    left: 22.5%;
    width: 45.6%;
    height: 2.4%;
    z-index: 1;
  }

  /*flipper*/

  .addresses {
    display: block;
    position: absolute;
    top: 36.5%;
    left: 5.7%;
    width: 100%;
  }

  .address {
    display: inline-block;
    margin-left: 0;
    margin-top: 4vw;
  }

  .flip-container, .flipper-front, .flipper-back {
    width: 88.6vw;
    height: 41.2vw;
  }

  .flip-container:hover .flipper {
    -webkit-transform: none;
    -moz-transform: none;
    -o-transform: none;
    transform: none;
  }

  .flip-container.active .flipper {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }

  .case-study-link {
    top: 0%;
    height: 5.6%;
    width: 50.2%;
    z-index: 1;
    display: block;
    &.active {
      top: 0%;
      height: 5.7%;
      width: 49.7%;
    }
    &-1 {
      left: 0.3%;
      &.active {
        left: 0.2%;
      }
    }
    &-2 {
      left: 49.8%;
      &.active {
        left: 50.2%;
      }
    }
  }

  /* Bookmarks (SP)  */

  .case-study-bookmark {
    top: -30px;
  }

  .service-bookmark {
    top: -30px;
  }

  .about-bookmark {
    top: -30px;
  }

  .contact-bookmark {
    top: -30px;
  }

  /*About us */

  .about-btn {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 22.8%;
    width: 61%;
  }

  /* Contact */
  .contact {
    &-section {
      padding: 79px 0 86px 0;
    }
    &-inner {
      width: 87%;
      margin: 0 auto;
    }
    &-form, &-text {
      float: none;
    }
    &-form {
      width: 100%;
    }
    &-text {
      width: 100%;
      padding: 0;
      text-align: center;
      bottom: 12vw;
      &-heading {
        font-size: 31px;
        margin-bottom: 13px;
        letter-spacing: 0;
      }
      &-label {
        font-size: 15px;
        margin-bottom: 14px;
      }
      &-p {
        font-size: 13px;
        line-height: 1.9;
        margin-bottom: 28px;
      }
    }
    &-table {
    }
    &-row {
    }
    &-cell {
    }
    &-input, &-textarea {
      padding: 10px 47px 13px 9px!important;
    }
    &-input {
      &-required {
        right: 11px;
      }
    }
    &-textarea {
      height: 205px;
    }
    &-btn {
      padding: 21px 12px 16px 12px !important;
      &-text {
        top: 48%;
        font-size: 15px;
        text-indent: 12px;
      }
      &-icon {
        top: 2px;
        left: -8px;
        width: 18px;
        height: 14px;
        background: transparent url('../img/icon__mail.png') no-repeat 0 0;
        background-size: 18px 14px;
      }
    }
    &-submit {
      margin-top: 15px !important;
    }
    &-confirmation {
      height: inherit;
      margin-top: 10px;
      &-heading {
        font-size: 31px;
        margin-bottom: 52px;
        text-align: center;
      }
      &-p {
        position: static;
        font-size: 15px;
      }
    }
  }

  .contact-form {
    top: 12vw;
    .hs_lastname, .hs_firstname,
    .hs_lastname_furigana, .hs_firstname_furigana,
    .hs_email, .hs_phone {
      width: 50% !important;
      float: left !important;
    }
    .hs-form textarea.hs-input {
      height: 150px;
    }
    .hs_submit {
      margin-top: 35px;
    }
  }

  .btn-fb, .btn-tw, .btn-li {
    position: absolute;
    z-index: 100;
    width: 6.8%;
    height: 38.2%;
    bottom: 13.6%;
  }

  .btn-fb {
    left: 28.7%;
  }

  .btn-tw {
    left: 46.4%;
  }

  .btn-li{
    left: 64.1%;
  }


}


@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {

  .flipper-front {
    opacity: 0;
  }

  .flipper-back {
    transform: none;
  }

  .flip-container:hover .flipper {
    -webkit-transform: none;
    -moz-transform: none;
    -o-transform: none;
    transform: none;
  }

}
