.section-slider {
  position: relative;
  margin: 50px 0 100px 0;
}
.c-slider {
  max-width: 875px;
  width: 88%;
  margin: 0 auto;
  z-index: 100;
  &__item {
    width: 270px;
    height: auto;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: transparent;
    transition: background-image .4s, box-shadow .4s;
    margin: 10px;
    &:hover {
      box-shadow: 0 0 10.64px 3.36px rgba(0, 0, 0, 0.13);
      z-index: 101;
    }
    &1 {
      background-image: url('../img/news/news1.png');
      &:hover {
        background-image: url('../img/news/news1_active.png');
      }
    }
    &2 {
      background-image: url('../img/news/news2.png');
      &:hover {
        background-image: url('../img/news/news2_active.png');
      }
    }
    &3 {
      background-image: url('../img/news/news3.png');
      &:hover {
        background-image: url('../img/news/news3_active.png');
      }
    }
    &4 {
      background-image: url('../img/news/news4.png');
      &:hover {
        background-image: url('../img/news/news4_active.png');
      }
    }
    &5 {
      background-image: url('../img/news/news5.png');
      &:hover {
        background-image: url('../img/news/news5_active.png');
      }
    }
    &6 {
      background-image: url('../img/news/news6.png');
      &:hover {
        background-image: url('../img/news/news6_active.png');
      }
    }
    &7 {
      background-image: url('../img/news/news7.png');
      &:hover {
        background-image: url('../img/news/news7_active.png');
      }
    }
    &8 {
      background-image: url('../img/news/news8.png');
      &:hover {
        background-image: url('../img/news/news8_active.png');
      }
    }
    &9 {
      background-image: url('../img/news/news9.png');
      &:hover {
        background-image: url('../img/news/news9_active.png');
      }
    }
    &10 {
      background-image: url('../img/news/news10.png');
      &:hover {
        background-image: url('../img/news/news10_active.png');
      }
    }
    &11 {
      background-image: url('../img/news/news11.png');
      &:hover {
        background-image: url('../img/news/news11_active.png');
      }
    }
    &12 {
      background-image: url('../img/news/news12.png');
      &:hover {
        background-image: url('../img/news/news12_active.png');
      }
    }
  }
  &__arrows {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 130px;
    &__left,
    &__right {
      position: absolute;
      display: block;
      height: 130px;
      width: 46px;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: cover;
      text-indent: -99999px;
    }
    &__left {
      left: 0;
      background-image: url('../img/news/slider-arrow-left.png');
    }
    &__right {
      right: 0;
      background-image: url('../img/news/slider-arrow-right.png');
    }
  }
  .slick-dots button:before {
    font-size: 10px;
    line-height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: black;
    }
    .slick-dots li.slick-active button:before, .slick-dots li button:hover:before, .slick-dots li button:focus:before {
      opacity: 1;
      color: orange;
    }
  }



@media (--small) {

  .c-slider {
    &__item{
      box-shadow: 0 0 10.64px 3.36px rgba(0, 0, 0, 0.13);
      z-index: 101;
      &1 {
        background-image: url('../img/news/news1_active.png');
      }
      &2 {
        background-image: url('../img/news/news2_active.png');
      }
      &3 {
        background-image: url('../img/news/news3_active.png');
      }
      &4 {
        background-image: url('../img/news/news4_active.png');
      }
      &5 {
        background-image: url('../img/news/news5_active.png');
      }
      &6 {
        background-image: url('../img/news/news6_active.png');
      }
      &7 {
        background-image: url('../img/news/news7_active.png');
      }
      &8 {
        background-image: url('../img/news/news8_active.png');
      }
      &9 {
        background-image: url('../img/news/news9_active.png');
      }
      &10 {
        background-image: url('../img/news/news10_active.png');
      }
      &11 {
        background-image: url('../img/news/news11_active.png');
      }
      &12 {
        background-image: url('../img/news/news12_active.png');
      }
    }
    &__arrows {
      display: block;
      position: absolute;
      z-index: 1;
      top: 101%;
      transform: none;

      &__right {
        right: 0;
        height: 30px;
        width: 90px;
        background-image: url('../img/news/sp/slider-arrow-right.png');
      }
      &__left {
        left: 0;
        height: 30px;
        width: 90px;
        background-image: url('../img/news/sp/slider-arrow-left.png');
      }
    }
    .slick-dots {
      bottom: -88px;
    }
    .slick-dots li {
      width: 4px;
      top: -56px;
      right: 8px;
    }
    .slick-dots button:before {
      font-size: 10px;
      }
    .slick-list {
    position: relative;
    left: 0.7%;
    bottom: 30px;
   }
  }
}

/* iphone 5 sized devices */
@media (max-width: 360px) {
  .c-slider {
    &__arrows {
      &__left {
        height: 27px;
        width: 74px;
      }
      &__right {
        height: 27px;
        width: 74px;
      }
    }
    .slick-dots button:before {
      font-size: 7px;
      left: -0.1px;
    }
  }
}
