.navigation-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  z-index: 10001;
}
.navigation-overlay.active {
  display: block;
  opacity: .6;
}

.page.nav-active {
  position: fixed;
  width: 100%;
  overflow: hidden;
}

.o-wrapper {
  left: 0;
  transition: left .3s;
}
.o-wrapper.nav-active {
  /* transform: translateX(82.6%); */
  left: 82.6vw;
}

.nav {
  font-family: var(--font-primary);
  font-size: 12px;
  background-color: white;
  display: block;
  width: 100%;
  position: fixed;
  z-index: 10000;
  top: 0;
  right: 0;
  &-list {
    list-style-type: none;
    margin: 28px 8% 32px 8%;
    @mixin clearfix;
    &-item {
      position: relative;
      top: 7px;
      float: right;
      margin-left: 5.5%;
      font-size: 14px;
      font-weight: 700;
      line-height: 2;
      letter-spacing: .02em;
      &.logo {
        float: left;
        top: 0;
        margin-left: 0;
        margin-right: 1.3%;
      }
    }
  }
}

.nav-sp {
  height: auto;
  margin-right: 0;
  padding: 0;
  position: fixed;
  top: 0;
  left: -82.6vw;
  width: 82.6vw !important;
  height: 100vh;
  max-height: inherit;
  z-index: 10001;
  background-color: #00001b;
  transition: left .3s;
  &-upper {
    text-align: right;
    height: 78px;
    padding-right: 23px;
  }
  &-list {
    margin-top: 20px;
    &-item {
      list-style: none;
      margin: 6vh 0 0 23px;
      &:after {
        position: absolute;
        content: '&nbsp;';
        width: 9px;
        height: 16px;
        background: transparent url('../img/sp/icon_arrow-right.png') no-repeat 0 0;
        background-size: 9px 16px;
        right: 23px;
        top: 50%;
        transform: translateY(-50%);
        text-indent: -99999px;
      }
      &:first-child {
        margin-top: 0;
      }
      a {
        display: block;
        width: 100%;
        font-size: 17px;
        color: var(--white);
        text-decoration: none;
      }
      &-sns {
        a {
          display: inline-block;
          width: 34px;
          height: 34px;
          border-radius: 34px;
          background-color: #fff;
          border: 1px solid #00093a;
          margin-left: 18px;
          text-indent: -9999px;
          &:first-child {
            margin-left: 0;
          }
        }
        &-fb {
          background: transparent url('../img/icon__fb.png') no-repeat center center;
          background-size: 8px 15px;
        }
        &-tw {
          background: transparent url('../img/icon__tw.png') no-repeat center center;
          background-size: 13px 11px;
        }
        &-in {
          background: transparent url('../img/icon__in.png') no-repeat center center;
          background-size: 15px 15px;
        }
        &::after {
          display: none;
        }
      }
    }
  }
}
.nav-active .nav-sp {
  left: 0;
}

.nav-toggle {
  display: block;
  position: absolute;
  cursor: pointer;
  left: 4.3%;
  top: 19px;
  padding: 10px 20px 10px 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color:transparent;
  z-index: 100;
}
.nav-toggle-close {
  top: 27px;
  color: var(--white) !important;
  z-index: 100;
  text-decoration: none;
}
.nav-toggle span, .nav-toggle span:before, .nav-toggle span:after {
  cursor: pointer;
  height: 1.5px;
  width: 19px;
  background: #00093a;
  position: absolute;
  display: block;
  content: '';
  transition: all 300ms ease-in-out;
}
.nav-toggle span:before {
  transform: translateY(6px);
}
.nav-toggle span:after {
  transform: translateY(-6px);
}
.nav-toggle.active span {
  background-color: transparent;
  transition: all 20ms ease-in-out;
}
.nav-toggle.active span:before, .nav-toggle.active span:after {
  transform: translateY(0);
}
.nav-toggle.active span:before {
  transform: rotate(45deg);
}
.nav-toggle.active span:after {
  transform: rotate(-45deg);
}
.nav-toggle:link, .nav-toggle:visited {
  color: #00093a;
  text-decoration: none;
}
.nav-toggle i {
  font-size: 8px;
  font-weight: 700;
  font-style: normal;
  position: relative;
  top: 9px;
}
.nav-toggle-open i {
  left: -6px;
}
.nav-toggle-close i {
  left: -2px;
}

.nav-list-item {
  a {
    color: #00001b;
    text-decoration: none;
    transition: color .4s, border-color .4s;
    border-bottom: 2px solid #fff;
    padding-bottom: 10px;
    &:hover {
      color: #f77f17;
      border-color: #f77f17;
    }
    &:active {
      color: #00001b;
    }
  }
  &.logo {
    a {
      border-bottom: none;
      opacity: 1;
      transition: opacity .4s;
      &:hover {
        opacity: .6;
      }
    }
  }
}

.sticky {
  z-index: 100;
  position: absolute;
  width: 5%;
  top: 28vw;
  left: 3.7%;
  &:first-child {
    margin-top: 0;
  }
  &-left {
    list-style-type: none;
    overflow: hidden;
    &-item {
      width: 100%;
      float: left;
      margin-top: 19px;
      a {
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}

.alien {
  padding-bottom: 23px;
}

.nav-contact {
  position: fixed;
  float: right;
  bottom: -120px;
  right: 30px;
  z-index: 2;
  transition: bottom 1s cubic-bezier(.19, 1.32, .48, 1);
  &.active {
    bottom: 6vw;
  }
  &-link {
    display: block;
    height: 60px;
    width: 60px;
    border-radius: 56px;
    text-indent: -99999px;
    transition: opacity .4s;
    &-contact {
      background: #00093a url('../img/btn__pc-mail-icon.png') no-repeat center center;
      background-size: 47px 39px;
      margin-bottom: 18px;
      border: 2px solid #cba06e;
      &:hover {
        opacity: .7;
      }
    }
    &-sns {
      background: var(--white) url('../img/icon__sns.png') no-repeat center center;
      background-size: 14px 16px;
      border: 1px solid #00093a;
      height: 41px;
      width: 41px;
      border-radius: 41px;
      left: 8px;
      top: -5px;
      &-links {
        position: relative;
        width: 88px;
        top: -1px;
        left: -48px;
      }
      &-link {
        position: relative;
        display: block;
        height: 34px;
        width: 34px;
        background-color: var(--white);
        border-radius: 34px;
        border: 1px solid #00093a;
        text-indent: -99999px;
        opacity: 0;
        transition: opacity .4s;
        span {
          display: block;
          width: 100%;
          height: 100%;
          transition: opacity .4s;
        }
        &-fb {
          top: 0;
          left: 0;
          span {
            background: transparent url('../img/icon__fb.png') no-repeat center center;
            background-size: 8px 15px;
          }
        }
        &-tw {
          top: 7px;
          left: 11px;
          transition-delay: .08s;
          span {
            background: transparent url('../img/icon__tw.png') no-repeat center center;
            background-size: 13px 11px;
            transition-delay: 2s;
          }
        }
        &-in {
          top: -14px;
          left: 50px;
          transition-delay: .16s;
          span {
            background: transparent url('../img/icon__in.png') no-repeat center center;
            background-size: 15px 15px;
            transition-delay: 3s;
          }
        }
        &:hover {
          span {
            opacity: .5;
          }
        }
      }
      &:hover {
        .nav-contact-link-sns-link {
          opacity: 1;
        }
      }
    }
  }
}

.header-sp {
  position: fixed;
  top: 0;
  left: 0;
  height: 61px;
  width: 100%;
  background-color: var(--white);
  z-index: 10000;
  transition: left .3s;
  &-logo {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    text-align: center;
    img {
      width: 123px;
    }
  }
  &-contact {
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 6.4%;
    transform: translateY(-50%);
    img {
      width: 33px;
    }
  }
}
.nav-active .header-sp {
  left: 82.6vw;
}

@media (--small) {

  .nav {

  }

  .sp-top-btn {
    position: fixed;
    z-index: 10000;
    bottom: 11px;
    right: 10px;
    width: 66px;
  }

}

@media (max-width: 320px) {
  .nav-sp-list-item a {
    font-size: 14px;
  }
}

/* iphone 5 sized devices */
@media (max-width: 360px) {
  .sp-top-btn {
    bottom: 72px;
  }
}
