/****************************
 * OBJECTS - WRAPPERS
 ****************************/

 .page {
   overflow-x: hidden;
 }

.o-wrapper {
  width: 100%;
  margin: 0 auto;

  &--small {
    max-width: var(--wrapper-small);
  }

  &--large {
    max-width: var(--wrapper-large);
  }
}

.o-ui-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
