.footer {
  background-color: #262f44;
  padding: 22px 0 22px 0;
}
.footer-copyright p {
  position: static;
  text-align: center;
  color: #ffff;
  font-size: 12px;
  letter-spacing: .2em;
}

@media (--small) {
  .footer {
    padding: 14px 0 14px 0;
  }
  .footer-copyright {
    font-size: 10px;
    letter-spacing: .06em;
  }
}